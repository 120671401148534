import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import { PauseCircleOutline, PlayCircleOutline } from '@material-ui/icons';
import { i18n } from '../utils/const';
import { srOnly } from '../utils/styles';

const useStyles = makeStyles(() => ({
  videoControl: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 3,
  },
  iconButton: {
    color: 'white',
    outline: 'none',
    padding: '.2em',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    '&:focus, &:hover': {
      outline: '5px auto -webkit-focus-ring-color',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
  },
  icon: {
    fontSize: '2.5rem',
  },
  srOnly,
}));

function VideoControls({ isPlaying, toggleVideoPlaying, lang }) {
  const classes = useStyles();

  function handleClick(event) {
    event.preventDefault();
    toggleVideoPlaying();
  }

  return (
    <Grid item className={classes.videoControl}>
      {
        !isPlaying
          ? (
            <IconButton
              className={classes.iconButton}
              aria-labelledby="playButton"
              // eslint-disable-next-line
              onClick={handleClick}
            >
              <span lang={lang} className={classes.srOnly} id="playButton">{i18n[lang].startVideo}</span>
              <PlayCircleOutline className={classes.icon} />
            </IconButton>
          )
          : (
            <IconButton
              className={classes.iconButton}
              aria-labelledby="stopButton"
              // eslint-disable-next-line
              onClick={handleClick}
            >
              <span lang={lang} className={classes.srOnly} id="stopButton">{i18n[lang].stopVideo}</span>
              <PauseCircleOutline className={classes.icon} />
            </IconButton>
          )
      }
    </Grid>
  );
}

export default VideoControls;
